<i18n>
{
  "de": {
    "uploadFile": "JSON-Datei auswählen:",
    "pendingMessage": "Portfolio wird importiert, dieser Vorgang kann mehrere Minuten dauern. Sie werden zum neuen Portfolio weitergeleitet, sobald der Vorgang abgeschlossen ist."
  }
}
</i18n>

<template>
  <div>
    <div v-if="error">
      {{ error }}
    </div>
    <div v-else-if="pending">
      {{ $t('pendingMessage') }}
    </div>
    <div v-else>
      <h3>{{ $t('uploadFile') }}</h3>
      <input id="portfolio-field" type="file" accept=".json" @change="onFileChanged" />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      fileReader: null,
      pending: false,
      error: null,
    }
  },

  methods: {
    ...mapActions({
      addPortfolio: 'portfolio/addPortfolio',
    }),

    onFileChanged(event) {
      const file = event.target.files[0]
      this.fileReader = new FileReader()
      this.fileReader.onloadend = this.onFileRead
      this.fileReader.readAsText(file)
    },

    async onFileRead(event) {
      const content = this.fileReader.result
      const parsedObj = JSON.parse(content)
      this.error = null
      this.pending = true
      try {
        const portfolio = await this.addPortfolio(parsedObj)
        this.$router.push({ name: 'adminPortfolioDetails', params: { portfolio_id: portfolio.id } })
      } catch (e) {
        this.error = e
      }
      this.pending = false
    },
  },
}
</script>
