<i18n>
{
  "de": {
    "pageTitle": "Portfolio importieren"
  }
}
</i18n>

<template>
  <MainLayout>
    <template #default>
      <h1>{{ $t('pageTitle') }}</h1>
      <ImportPortfolio />
    </template>
  </MainLayout>
</template>

<script>
import MainLayout from '@/pages/layouts/MainLayout.vue'

import ImportPortfolio from '@/components/admin-portfolios/ImportPortfolio.vue'

export default {
  name: 'adminImportPortfolio',
  components: {
    MainLayout,
    ImportPortfolio,
  },

  data() {
    return {
      name: null,
      error: null,
    }
  },

  head() {
    return {
      title: () => {
        return { inner: this.$t('pageTitle') }
      },
    }
  },
}
</script>
